input[type=search] { 
	background-color: white;  
	text-align: left;
	/*cursor: pointer;*/
	display: block; 
	width: 100%; 
	letter-spacing: 2px;        
	text-shadow: 0 0 2px black;       
	word-spacing: 1px;              
}

/* input[type="search"]::-webkit-search-cancel-button {
  Remove default 
  -webkit-appearance: none;*/
    
  /* Now your own custom styles */
 /*
 height: 10px;
  width: 10px;
  background: white;*/
  /* Will place small red box on the right of input (positioning carries over)

} */