/* body {
  /* background: url(./assets/flowers.jpg), url(./assets/sea.jpg);*/ /*
  background: url(https://s3.us-east-2.amazonaws.com/picturesforanyone/images/flowers.jpg);
  background-repeat: no-repeat, repeat;
  background-size: cover;
  background-color: #950740;
  color: white;
} */

#content {
  text-align: center;
  padding-top: 0%;

  font-size: 2em;
}

html {
  height: 100%;
  font-size: 18px;
}

@media only screen and (max-width: 560px) {
  html {
    font-size: 15px;
  }
}

input:focus {
  outline: none;
}

/* Reusable Component - Image Container */

.image-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  background: #fff;
}

.image-container img {
  width: 100%;
}

hr {
  width: 400px;
  border-top: 1 px solid #f8f8f8;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

h1 {
  padding: 0.66rem 0;
  text-align: center;
  background-color: #5a3550;
  font-family: Poppins, sans-serif;
  font-size: 1.8rem;
  color: #fff;
}

h2 {
  padding: 0.66rem 0;
  margin-top: 10px;
  text-align: center;
  font-family: Poppins, sans-serif;
  font-size: 1.8rem;
  color: #000;
}
/*#913d7b*/
.App {
  font-family: "Work Sans", sans-serif;
  text-align: center;
}

.cardslist {
  margin: 10px 10px;
}

.cards {
  background-color: #5a3550;
  color: #f8f8f8;
}

a {
  color: white;
}
